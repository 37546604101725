import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"

const heroText = "Would you like to discuss a project?"

export default function Contact() {
  return (
    <Layout
      bodyClass="contact"
      heroTitle="Contact Us"
      heroText={heroText}
      bcText="CONTACT"
    >
      <section className="contact-section">
        <Container>
          <Row>
            <Col sm={12} md={5} lg={4}>
              <h1>We are REDI to satisfy your needs</h1>
              <form action="https://formspree.io/f/mknkrgkl" method="POST">
                <input type="text" name="name" id="name" placeholder="Name" />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                />
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your Message"
                ></textarea>
                <button type="submit" className="square-button">
                  SEND <span className="fas fa-chevron-right"></span>
                </button>
              </form>
            </Col>
            <Col
              sm={12}
              md={7}
              lg={{ span: 7, offset: 1 }}
              className="right-contact"
            >
              <img
                className="map-redi"
                src="images/map-redi.jpg"
                alt="Google Map"
              />
              <Row className="info-boxes">
                <Col sm={12} lg={6}>
                  <span className="small-title">CALL US</span>
                  <p>
                    <a href="tel:+302310439392">Phone: (+30) 2310 43 93 92</a>
                  </p>
                </Col>
                <Col sm={12} lg={{ span: 5, offset: 1 }}>
                  <span className="small-title">EMAIL US</span>
                  <p>
                    <a href="mailto:info@rediengineering.gr">
                      info@rediengineering.gr
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="info-boxes">
                <Col sm={12}>
                  <span className="small-title">OUR ADDRESS</span>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/tK5fpURPr64sm7Gy7"
                    >
                      Ethnikis Antistaseos 7B, 55134, Thessaloniki, Greece
                    </a>
                  </p>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/6MgXsDTWZYwkDBgs5"
                    >
                      Mpastia K. 6, 50132, Kozani, Greece
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
